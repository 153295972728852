import './style.css'
import * as THREE from 'three'
//import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import * as dat from 'dat.gui'

//Texture loader
const loader = new THREE.TextureLoader()
const height = loader.load('/textures/depth-map.png')
//const texture = loader.load('/textures/rock-stone-texture.jpg')
const alpha = loader.load('/textures/alpha-map.png')

// Debug
//const gui = new dat.GUI()

// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()

// Objects
const geometry = new THREE.PlaneGeometry(3, 3, 64, 64)

// Materials
const material = new THREE.MeshStandardMaterial({
    color: 'gray',
    //map: texture,
    displacementMap: height,
    displacementScale: 0.6,
    alphaMap: alpha,
    transparent: true,
    depthTest: false,
    wireframe: true
})


// Mesh
const plane = new THREE.Mesh(geometry, material)
plane.position.z = 2
scene.add(plane)
plane.rotation.x = 181
//gui.add(plane.rotation, 'x').min(0).max(500)
//gui.add(plane.position, 'z').min(-10).max(10)

// Lights

const pointLight = new THREE.PointLight('#00b3ff', 2)
pointLight.position.x = 0.2
pointLight.position.y = 20
pointLight.position.z = 20
scene.add(pointLight)

// gui.add(pointLight.position, 'x').min(-100).max(100)
// gui.add(pointLight.position, 'y').min(-100).max(100)
// gui.add(pointLight.position, 'z').min(-100).max(100)

const lightColor = {color: '#0000ff'}
// gui.addColor(lightColor, 'color').onChange(()=>{
//     pointLight.color.set(lightColor.color)
// })

/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100)
camera.position.x = 0
camera.position.y = 0
camera.position.z = 3
scene.add(camera)

// Controls
// const controls = new OrbitControls(camera, canvas)
// controls.enableDamping = true

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    alpha: true,
    
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

/**
 * Animate
 */

document.addEventListener('mousemove', animateTerrain)

let mouseY = 0

function animateTerrain(event){
    mouseY = event.clientY
}

const clock = new THREE.Clock()

const tick = () =>
{

    const elapsedTime = clock.getElapsedTime()

    // Update objects
    plane.rotation.z = .2 * elapsedTime
    //plane.material.displacementScale = 0.5+mouseY * 0.008

    // Update Orbital Controls
    // controls.update()

    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()